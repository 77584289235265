var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('page-header',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('awesome-table',{attrs:{"id":"bundles","labels":_vm.labels,"items":_vm.bundles,"options":{
            filters: false,
            columnsSelector: true
        },"pagination":_vm.pagination,"filters":_vm.filters},on:{"add-button-click":function($event){return _vm.$router.push('/bundles/new')},"row-click":function($event){return _vm.$router.push(("/bundles/" + ($event.id) + "/edit"))},"mouse-wheel-click":function($event){return _vm.$openNewTab(("/bundles/" + ($event.id) + "/edit"))},"pagination-change":_vm.onPaginationChange,"filter-change":_vm.onFilterChange},scopedSlots:_vm._u([{key:"items.products_quantity",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.getProductsQuantity(item.bundleProducts))+"\n        ")]}},{key:"items.price",fn:function(ref){
        var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("variantFixedPrice")(_vm.getBundlePrice(item.bundleProducts)))+"\n        ")]}},{key:"items.actions",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"action-icon",attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.removeBundle(item.id)}}},[_c('i',{staticClass:"far fa-trash-alt"})])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
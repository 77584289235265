<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            id="bundles"
            :labels="labels"
            :items="bundles"
            :options="{
                filters: false,
                columnsSelector: true
            }"
            :pagination="pagination"
            :filters="filters"
            @add-button-click="$router.push('/bundles/new')"
            @row-click="$router.push(`/bundles/${$event.id}/edit`)"
            @mouse-wheel-click="$openNewTab(`/bundles/${$event.id}/edit`)"
            @pagination-change="onPaginationChange"
            @filter-change="onFilterChange"
        >
            <template #[`items.products_quantity`]="{ item }">
                {{ getProductsQuantity(item.bundleProducts) }}
            </template>
            <template #[`items.price`]="{ item }">
                {{ getBundlePrice(item.bundleProducts) | variantFixedPrice }}
            </template>
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.stop.prevent="removeBundle(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Bundles',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Bundles',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Bundles',
                    active: true
                }
            ],
            bundles: [],
            labels: [
                'name',
                {
                    value: 'products_quantity',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'price',
                    sortable: false,
                    filterable: false
                },
                {
                    value: 'created_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'updated_at',
                    filter: 'formatDate',
                    type: 'date'
                },
                {
                    value: 'actions',
                    sortable: false,
                    filterable: false
                }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters)
        };
    },

    mounted() {
        this.fetchBundles();
    },

    methods: {
        ...mapActions({
            getBundles: 'bundles/index',
            deleteBundle: 'bundles/delete'
        }),

        async fetchBundles() {
            try {
                const options = {
                    ...this.pagination,
                    filters: this.filters
                };

                const { rows, count } = await this.getBundles(options);

                if (options.currentPage !== this.pagination.currentPage) {
                    return;
                }

                this.bundles = rows;
                this.pagination.pages = Math.ceil(
                    count / this.pagination.perPage
                );
                this.pagination.total = count;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        async removeBundle(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this bundle?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteBundle(id);

                this.bundles = this.bundles.filter(item => item.id !== id);

                this.$toaster('Bundle has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchBundles();
        },

        onFilterChange(filters) {
            this.filters = { ...filters };
            this.pagination.currentPage = 1;

            this.fetchBundles();
        },

        getProductsQuantity(bundleProducts) {
            return bundleProducts.reduce(
                (acc, { quantity }) => acc + quantity,
                0
            );
        },

        getBundlePrice(bundleProducts) {
            return bundleProducts.reduce(
                (acc, { price, quantity }) => acc + price * quantity,
                0
            );
        }
    }
};
</script>
